import {Injectable} from '@angular/core';
import {IApiResponse, ICredentials, ILoginResponse} from "@app/core/models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from 'environments';
import {catchError, map} from "rxjs/operators";
import {LocalStorageService} from "@app/core/services";
import {Observable} from "rxjs";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authBaseUrl = `${environment.api.url}/admin/accounts`;

  private accessToken: string | null = null;
  private accessTokenFlag = 'accessToken';

  private refreshToken: string | null = null;
  private refreshTokenFlag = 'refreshToken';
  private clientIdHeader = new HttpHeaders({
    'clientId': 'cpl-client',
    "ngrok-skip-browser-warning": "69420",
  });

  private expiresAt = 0;

  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
  }

  get authenticated(): boolean {
    return this.accessToken !== null;
  }

  login(credentials: ICredentials) {
    return this.httpClient.post<IApiResponse<ILoginResponse>>(`${this.authBaseUrl}/login`, {
      context: "",
      data: credentials
    }, {headers: this.clientIdHeader})
      .pipe(
        map(res => {
          if (res.data) {
            this.setToken(res.data.accessToken, res.data.refreshToken);
            this.expiresAt = res.data.expiresIn;
            return res.data;
          } else
            throw Error(`Data didn't match the expected interface`, {cause: res});
        }),
        catchError(err => {
          throw Error(`Error on sign in request for ${credentials}`, err);
        })
      )
  }

  refreshAccessToken(): Observable<string | null> {
    return this.httpClient.post<IApiResponse<ILoginResponse>>(`${this.authBaseUrl}/refresh-token`, {
      data: {refreshToken: this.refreshToken},
    }, {headers: this.clientIdHeader})
      .pipe(
        map(res => {
          if (res.data) {
            this.setToken(res.data.accessToken, res.data.refreshToken);
            this.expiresAt = res.data.expiresIn;
            return res.data.accessToken;
          } else {
            return null;
          }
        })
      )
  }

  setToken(aToken: string | null, rToken?: string | null): void {
    if (aToken) {
      this.accessToken = aToken;
      this.localStorage.setItem(this.accessTokenFlag, aToken);
    }
    if (rToken) {
      this.refreshToken = rToken;
      this.localStorage.setItem(this.refreshTokenFlag, rToken);
    }
  }

  clearToken() {
    this.accessToken = null;
    this.localStorage.removeItem(this.accessTokenFlag);

    this.refreshToken = null;
    this.localStorage.removeItem(this.refreshTokenFlag);
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  logout(): void {
    this.clearToken();
    this.router.navigateByUrl('/auth');
  }
}
